import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ReactApexChart from "react-apexcharts";

const series = [40, 60, 40, 25]

const options = {    
    chart: {
      height: 248,
        type: 'donut',
    },
    labels: ["Invest", "Income", "Spends", "Installment"],
    plotOptions: {
        pie: {
          donut: {
            size: '75%',
            
          }
        }
    },
    legend: {
        show: false,
    },
    
    colors: ['#2cb57e', '#0576b9', '#f5bd58', '#f56e50'],
  
  };

const CurrentStatistic = () => {
  return (
    <Col xl={3}>
        <Card>
            <CardBody>
                <div className="float-end">
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="text-reset" to="#">
                            <span className="text-muted">Status:</span> <span className="fw-semibold">All<i className="mdi mdi-chevron-down ms-1"></i></span>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">All</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Delivered</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Shipped</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Cancelled</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <h5 className="card-title mb-3 me-2">Current Statistic</h5>

                <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                    height={248}
                    className="apex-charts"
                />      

                
                

                <div className="mt-4 text-center">
                    <div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
                            <div className="d-flex">
                                <i className="mdi mdi-circle font-size-12 mt-1 text-primary"></i>
                                <div className="flex-grow-1 ms-2">
                                    <p className="mb-0">Income (36.4%)</p>
                                </div>
                            </div>
                            <div>
                               <h5 className="mb-0 font-size-14">$265,745.26</h5>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div className="d-flex">
                                <i className="mdi mdi-circle font-size-12 mt-1 text-warning"></i>
                                <div className="flex-grow-1 ms-2">
                                    <p className="mb-0">Spends (24.2%)</p>
                                </div>
                            </div>
                            <div>
                               <h5 className="mb-0 font-size-14">$74,965.45</h5>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div className="d-flex">
                                <i className="mdi mdi-circle font-size-12 mt-1 text-danger"></i>
                                <div className="flex-grow-1 ms-2">
                                    <p className="mb-0">Installment (15.2%)</p>
                                </div>
                            </div>
                            <div>
                               <h5 className="mb-0 font-size-14">$51,652.26</h5>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center pt-2">
                            <div className="d-flex">
                                <i className="mdi mdi-circle font-size-12 mt-1 text-success"></i>
                                <div className="flex-grow-1 ms-2">
                                    <p className="mb-0">Invest (24.2%)</p>
                                </div>
                            </div>
                            <div>
                               <h5 className="mb-0 font-size-14">$13,521.62</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    </Col>
  )
}

export default CurrentStatistic