import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';



//import images
import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';

function PageTwoStepVerification(props) {

    document.title = "Two Step Verification | Samply - React Admin & Dashboard Template";

    const getInputElement = (index) => {
        return document.getElementById('digit' + index + '-input');
    }

    const moveToNext = (index) => {
        if (getInputElement(index).value.length === 1) {
            if (index !== 4) {
                getInputElement(index + 1).focus();
            } else {
                getInputElement(index).blur();
                // Submit code
                console.log('submit code');
            }
        }
    }

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5 text-muted">
                                <Link to="/dashboard" className="auth-logo">
                                    <img src={logoDark} alt="" height="28" className="auth-logo-dark" />
                                    <img src={logoLight} alt="" height="28" className="auth-logo-light" />
                                </Link>
                                <p className="font-size-15 text-muted mt-3">Responsive <b>Bootstrap 5</b> Admin Dashboard</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card>
                                <CardBody>
                                    <div className="p-2">
                                        <div className="text-center">

                                            <div className="avatar-md mx-auto">
                                                <div className="avatar-title rounded-circle bg-light">
                                                    <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                                                </div>
                                            </div>
                                            <div className="p-2 mt-4">

                                                <h4>Verify your email</h4>
                                                <p className="mb-5 text-muted">Please enter the 4 digit code sent to <span
                                                    className="font-weight-semibold">example@abc.com</span></p>

                                                <Form>
                                                    <Row>
                                                        <Col className="col-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit1-input" className="visually-hidden">Digit 1</label>
                                                                <input type="text"
                                                                    className="form-control form-control-lg bg-light border-light text-center"
                                                                    maxLength="1"
                                                                    id="digit1-input" onKeyUp={() => moveToNext(1)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit2-input" className="visually-hidden">Digit 2</label>
                                                                <input type="text"
                                                                    className="form-control form-control-lg bg-light border-light text-center"
                                                                    maxLength="1"
                                                                    id="digit2-input" onKeyUp={() => moveToNext(2)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit3-input" className="visually-hidden">Digit 3</label>
                                                                <input type="text"
                                                                    className="form-control form-control-lg bg-light border-light text-center"
                                                                    maxLength="1"
                                                                    id="digit3-input" onKeyUp={() => moveToNext(3)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit4-input" className="visually-hidden">Digit 4</label>
                                                                <input type="text"
                                                                    className="form-control form-control-lg bg-light border-light text-center"
                                                                    maxLength="1"
                                                                    id="digit4-input" onKeyUp={() => moveToNext(4)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                                <div className="mt-4">
                                                    <Link to="/dashboard" className="btn btn-success w-md">Confirm</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>Did't receive a code ? <Link to="#" className="fw-semibold text-decoration-underline text-primary"> Resend </Link> </p>
                                <p>© {(new Date().getFullYear())} <b>Samply</b>. Crafted with <i className="mdi mdi-heart text-danger"></i> by Pichforest</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PageTwoStepVerification;