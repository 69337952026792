import React from 'react'
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Progress,UncontrolledDropdown } from 'reactstrap';
import Vector from './VectorMap';

const SalesByLocation = () => {
  return (
                         <Col xl={4}>         
                                  <Card>                                    
                                    <CardBody>
                                        <div className="d-flex flex-wrap align-items-center mb-4">
                                            <h5 className="card-title me-2">Sales by Locations</h5>
                                            <div className="ms-auto">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="text-reset" to="#">
                                                        <span className="text-muted font-size-12">Sort By:</span> <span className="fw-medium">World<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                    </DropdownToggle>

                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem className="dropdown-item" to="#">USA</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Russia</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Australia</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                        <div
                                                // data-colors='["--vz-light", "--vz-success", "--vz-primary"]'
                                                style={{ height: "244px" }} dir="ltr">
                                                <Vector
                                                    value="world_mill"
                                                />
                                            </div>
                                        {/* <div id="sales-by-locations" style={{height: "244px"}}></div> */}

                                        <div className="px-2 py-2 mt-2">
                                            <p className="mb-1">USA <span className="float-end">75%</span></p>
                                            
                                            <Progress color="primary" className="mt-2" striped value={75} style={{height : "6px"}} />

                                            <p className="mt-3 mb-1">Russia <span className="float-end">55%</span></p>
                                                
                                            <Progress color="success" className="mt-2" striped value={55} style={{height : "6px"}} />

                                            <p className="mt-3 mb-1">Australia <span className="float-end">85%</span></p>

                                            <Progress color="danger" className="mt-2" striped value={85} style={{height : "6px"}} />
                                            
                                        </div>
                                    </CardBody>
                                    
                                </Card>
                                
                            </Col> 
  )
}

export default SalesByLocation