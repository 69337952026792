import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row, Collapse, Button } from 'reactstrap';

const Collapses = () => {
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);


    const t_col5 = () => {
        setcol5(!col5);
    };

    const t_col6 = () => {
        setcol6(!col6);
    };

    const t_col7 = () => {
        setcol7(!col7);
    };

    const t_col8 = () => {
        setcol6(!col6);
        setcol7(!col7);
    };

    const [isOpen, setIsOpen] = useState(true);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    return (
        <React.Fragment>
            <Col lg={12}>
                <Card id="collapse">
                    <CardBody>
                        <CardTitle className="h4 mb-0">Collapse</CardTitle>
                        <Row>
                            <Col xl={6}>
                                <div className="mt-4">
                                    <h5 className="font-size-14">Example</h5>
                                    <p className="card-title-desc">
                                        You can use a link with the{" "}
                                        <code className="highlighter-rouge">href</code>{" "}
                                        attribute, or a button with the{" "}
                                        <code className="highlighter-rouge">data-target</code>{" "}
                                        attribute. In both cases, the{" "}
                                        <code className="highlighter-rouge">
                                            {" "}
                                            data-toggle=&quot;collapse&ldquo;
                                        </code>{" "}
                                        is required.
                                    </p>

                                    <div className="d-flex gap-2 flex-wrap mb-3">
                                        <Link
                                            to="#"
                                            onClick={t_col5}
                                            style={{ cursor: "pointer" }}
                                            className="btn btn-primary mo-mb-2"
                                        >
                                            Link with href{" "}
                                        </Link>
                                        <button
                                            onClick={t_col5}
                                            className="btn btn-primary mo-mb-2"
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Button with data-bs-target
                                        </button>
                                    </div>
                                    <Collapse isOpen={col5}>
                                        <Card>
                                            <CardBody>
                                                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mt-4 mt-xl-0">
                                    <h5 className="font-size-14">Basic Horizontal Collapse</h5>
                                    <p className="card-title-desc">
                                        The collapse plugin also supports horizontal collapsing. Add the <code>.collapse-horizontal</code> modifier class to transition the <code>width</code> instead of <code>height</code> and set a <code>width</code> on the immediate child element.
                                    </p>

                                    <p>
                                        <Button color="primary" type="button" onClick={toggleCollapse} aria-expanded={isOpen} aria-controls="collapseWidthExample">
                                            Toggle width collapse
                                        </Button>
                                    </p>

                                    <div style={{ minHeight: '130px' }}>
                                        <Collapse isOpen={isOpen} className="collapse-horizontal" id="collapseWidthExample">
                                            <Card>
                                                <CardBody style={{ width: '300px' }}>
                                                    This is some placeholder content for a horizontal collapse. It's hidden by default and shown when triggered.
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div className="mt-4">
                                    <h5 className="font-size-14">Multiple targets</h5>
                                    <p className="card-title-desc">
                                        A <code>&lt;button&gt;</code> or{" "}
                                        <code>&lt;a&gt;</code> can show and hide multiple
                                        elements by referencing them with a selector in its{" "}
                                        <code>href</code> or <code>data-bs-target</code>{" "}
                                        attribute.
                                    </p>

                                    <div className="d-flex gap-2 flex-wrap mb-3">
                                        <Link
                                            to="#"
                                            onClick={t_col6}
                                            style={{ cursor: "pointer" }}
                                            className="btn btn-primary"
                                        >
                                            Toggle first element
                                        </Link>
                                        <button
                                            onClick={t_col7}
                                            className="btn btn-primary"
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Toggle second element
                                        </button>

                                        <button
                                            onClick={t_col8}
                                            className="btn btn-primary"
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Toggle both element
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Collapse isOpen={col6}>
                                                <Card>
                                                    <CardBody className="border shadow-none text-muted mb-0">
                                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                        </div>
                                        <div className="col">
                                            <Collapse isOpen={col7}>
                                                <Card>
                                                    <CardBody className="border shadow-none text-muted mb-0">
                                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Collapses;