import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { cryptoCurrencyPrices } from '../../common/data/crypto';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';


const CryptocurrencyPrice = () => {
    return (
        <Col xl={8}>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-4">Cryptocurrency Prices by Market Cap</h5>
                        </div>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="text-reset" to="#">
                                    <span className="fw-semibold">Report By:</span> <span
                                        className="text-muted">Monthly<i
                                            className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem className="dropdown-item" href="#">Yearly</DropdownItem>
                                    <DropdownItem className="dropdown-item" href="#">Monthly</DropdownItem>
                                    <DropdownItem className="dropdown-item" href="#">Weekly</DropdownItem>
                                    <Link className="dropdown-item" href="#">Today</Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table align-middle table-striped table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th className="align-middle">#</th>
                                    <th className="align-middle" style={{ width: "160px" }}>Coin</th>
                                    <th className="align-middle" style={{ width: "100px" }}>Symbol</th>
                                    <th className="align-middle">Price</th>
                                    <th className="align-middle" style={{ width: "100px" }}>1h</th>
                                    <th className="align-middle" style={{ width: "100px" }}>24h</th>
                                    <th className="align-middle">Mkt Cap</th>
                                    <th className="align-middle">Last 7 Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(cryptoCurrencyPrices || []).map((item, key) => (
                                    <tr key={key}>
                                        <td><i className="mdi mdi-star-outline font-size-18 me-2 align-middle"></i></td>
                                        <td className="fw-semibold"><i className={`${item.coinIcon} font-size-24 text-${item.coinColor} align-middle me-2`}></i>{item.coin}</td>
                                        <td>
                                            {item.symbol}
                                        </td>
                                        <td>
                                            {item.price}
                                        </td>
                                        <td>
                                            <span className={`text-${item.time1hColor}`}> {item.time1h}</span>
                                        </td>
                                        <td>
                                            <span className={`text-${item.time1hColor}`}>{item.time24h}</span>
                                        </td>
                                        <td>
                                            {item.MktCap}
                                        </td>
                                        <td>
                                            <div id="chart-1">
                                            <ReactApexChart
                                                    options={item.option}
                                                    series={item.series}
                                                    type="line"
                                                    height={30}
                                                    width={120}
                                                    className="apex-charts"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>


                </CardBody>
            </Card>
        </Col>
    )
}

export default CryptocurrencyPrice;