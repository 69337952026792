import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";
import { recentFills } from '../../common/data/crypto';

const RecentFills = () => {
  return (
                        <Col xl={4}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-2">Recent Fills</h5>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="text-reset" to="#">
                                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                    </DropdownToggle>

                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem className="dropdown-item" to="#">Yearly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Monthly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Weekly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Today</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                        

                                        <SimpleBar className="px-3 mx-n3" style={{ height: "401px" }}>
                                        {(recentFills || []).map((item,key) =>(
                                            <div className="d-flex align-items-center py-3 border-bottom" key={key}>
                                            <div className="flex-shrink-0">
                                                <ul className="list-inline recent-fills mb-0 mt-2">
                                                    <li className="list-inline-item me-0">
                                                        <div className="avatar">
                                                            <div className={`avatar-title rounded-circle bg-${item.icon1Color} bg-soft`}>
                                                                <i className={`${item.icon1} font-size-20 text-${item.icon1Color}`}></i>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <div className="avatar">
                                                            <div className={`avatar-title rounded-circle bg-${item.icon2Color} bg-soft`}>
                                                                <i className={`${item.icon2} font-size-20 text-${item.icon2Color}`}></i>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="flex-grow-1 ms-2 overflow-hidden">
                                                <h5 className="mb-1 font-size-15 text-truncate">{item.range1} <i className="mdi mdi-swap-horizontal mx-2 font-size-18"></i> {item.range2}</h5>
                                                <p className="text-muted mb-0">{item.timeAgo} minutes ago</p>
                                            </div>

                                            <div className="flex-shrink-0 text-end ms-4">
                                                <h5 className="mb-1 font-size-15">{item.value}</h5>
                                                <p className="text-muted mb-0">Value</p>
                                            </div>
                                        </div>    
                                        ))}                                              
                                            
                                        </SimpleBar>
                                    </CardBody>
                                </Card>
                            </Col>
  )
}

export default RecentFills