import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Input, InputGroup, Label, Row } from 'reactstrap';
import { cryptoSlider } from '../../common/data/crypto';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Mousewheel } from 'swiper/modules';

const QuickTransfer = () => {
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <div className="align-items-center d-flex mb-4">
                            <h4 className="card-title mb-0 flex-grow-1">Quick Transfer</h4>
                            <div className="flex-shrink-0">
                                <select className="form-select form-select-sm mb-0 my-n1">
                                    <option defaultValue="ltc">65,568 LTC</option>
                                    <option value="eth">625,487 ETH</option>
                                    <option value="Week">526,478 BTH</option>
                                </select>
                            </div>
                        </div>

                        <div className="">
                            <Row>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Currency :</label>
                                        <select className="form-select">
                                            <option>BTC</option>
                                            <option>ETH</option>
                                            <option>LTC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Payment Method :</label>
                                        <select className="form-select">
                                            <option>Wallet Balance</option>
                                            <option>Credit / Debit Card</option>
                                            <option>PayPal</option>
                                            <option>Payoneer</option>
                                        </select>
                                    </div>
                                </div>
                            </Row>
                            <InputGroup className="mb-3">
                                <Label className="input-group-text">Amount</Label>
                                <Input type="text" className="form-control" placeholder="0" />
                            </InputGroup>
                        </div>

                        <div className="d-flex justify-content-between pt-2">
                            <h5 className="card-title">
                                Recent Contacts
                            </h5>
                            <Link to="" className="text-primary fw-bold">View more</Link>
                        </div>

                        <div className="mt-3">
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={24}
                                mousewheel={true}
                                modules={[Mousewheel]}
                                className="slider events"
                            >
                                <div className="swiper-wrapper">
                                    {(cryptoSlider || []).map((item, key) => (
                                        <SwiperSlide key={key} className="event-list">

                                            <div className="text-center">
                                                <img className="rounded-circle avatar-md" src={item.image} alt="Header Avatar" />
                                                <h5 className="font-size-16 mt-2 mb-1">{item.name}</h5>
                                                <p className="text-muted font-size-13 mb-0">{item.tag}</p>
                                            </div>

                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                        </div>

                        <Row className="row mt-3 align-items-center">
                            <div className="col-sm-7">
                                <div className="mt-3">
                                    <p className="text-muted mb-0"><span className="text-primary me-1">*</span>Manage your money
                                        and contact your money to your friends & brands.</p>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="mt-3">
                                    <Link href="#" className="btn btn-primary w-100">Transfer Now</Link>
                                </div>
                            </div>
                        </Row>


                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default QuickTransfer;