import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import CurrentStatistic from './CurrentStatistic';

const series = [{
  name: 'Profit',
  data: [{
    x: '2:00PM',
    y: [450, 850]
  }, {
    x: '2:30PM',
    y: [370, 750]
  }, {
    x: '3:00PM',
    y: [550, 800]
  }, {
    x: '3:30PM',
    y: [500, 900]
  }, {
    x: '4:00PM',
    y: [400, 750]
  }, {
    x: '4:30PM',
    y: [550, 800]
  }, {
    x: '5:00PM',
    y: [500, 800]
  }, {
    x: '5:30PM',
    y: [400, 700]
  }, {
    x: '6:00PM',
    y: [500, 750]
  }, {
    x: '6:30PM',
    y: [380, 750]
  }, {
    x: '7:00PM',
    y: [550, 850]
  }, {
    x: '7:30PM',
    y: [600, 950]
  }, {
    x: '8:00PM',
    y: [500, 900]
  }]
},
{
  name: 'Loss',
  data: [{
    x: '1',
    y: [500, 750]
  }, {
    x: '2',
    y: [550, 900]
  }, {
    x: '3',
    y: [500, 680]
  }, {
    x: '4',
    y: [400, 800]
  }, {
    x: '5',
    y: [550, 850]
  }, {
    x: '6',
    y: [650, 750]
  }, {
    x: '7',
    y: [650, 850]
  }, {
    x: '8',
    y: [500, 750]
  }, {
    x: '9',
    y: [600, 850]
  }, {
    x: '10',
    y: [450, 900]
  }, {
    x: '11',
    y: [370, 750]
  }, {
    x: '12',
    y: [550, 650]
  }, {
    x: '13',
    y: [700, 950]
  }]
}];

var options = {
  chart: {
    type: 'rangeBar',
    height: 400,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '42%',
      dataLabels: {
        position: 'top',
      },
    }
  },
  stroke: {
    show: true,
    width: 4,
    colors: '#ffffff',
  },

  dataLabels: {
    enabled: false
  },
  legend: {
    show: false,
  },

  tooltip: {
    shared: true,
    intersect: false
  },
  xaxis: {
    categories: ['2:00PM', '2:30PM', '3:00PM', '3:30PM', '4:00PM', '4:30PM', '5:00PM', '5:30PM', '6:00PM', '6:30PM', '7:00PM', '7:30PM', '8:00PM'],
  },

  colors: ['#0576b9', '#f56e50'],
};
const MarketOveriview = () => {
  return (
    <Row>
    <Col xl={9}>
        <Card>
            <CardBody className="pb-2">
                <div className="d-flex flex-wrap align-items-center mb-2">
                    <h5 className="card-title me-2">Market Overview</h5>
                    <div className="ms-auto">
                        <div>
                            <button type="button" className="btn btn-primary btn-sm me-1">
                                ALL
                            </button>
                            <button type="button" className="btn btn-light btn-sm me-1">
                                1M
                            </button>
                            <button type="button" className="btn btn-light btn-sm me-1">
                                6M
                            </button>
                            <button type="button" className="btn btn-light btn-sm">
                                1Y
                            </button>
                        </div>
                    </div>
                </div>
               <Row className="align-items-center">
                   <Col lg={9}>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="rangeBar"
                        height={400}                        
                        className="apex-charts"
                    />                         
                   </Col>

                   <Col lg={3}>
                        <Card>
                            <CardBody className="p-3">
                                <div className="d-flex">
                                    <i className="mdi mdi-circle font-size-12 mt-1 text-primary"></i>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="font-size-18">$236,452.63</h5>
                                        <p className="mt-2 mb-0 text-muted">Total Earning</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody className="p-3">
                                <div className="d-flex">
                                    <i className="mdi mdi-circle font-size-12 mt-1 text-success"></i>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="font-size-18">$184,426.74</h5>
                                        <p className="mt-2 mb-0 text-muted">Total Profit</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        <Card className="mb-xl-0">
                           <CardBody className="p-3">
                                <div className="d-flex">
                                    <i className="mdi mdi-circle font-size-12 mt-1 text-danger"></i>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="font-size-18">$352,426.74</h5>
                                        <p className="mt-2 mb-0 text-muted">Total Loss</p>
                                    </div>
                                </div>
                           </CardBody>
                        </Card>
                   </Col>
               </Row>
            </CardBody>
        </Card>
    </Col>

    <CurrentStatistic/>
</Row>
  )
}

export default MarketOveriview