import React, { useState } from "react"
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
} from "reactstrap"

const EmailToolbar = () => {
  const [folder_Menu, setfolder_Menu] = useState(false)
  const [tag_Menu, settag_Menu] = useState(false)
  const [more_Menu, setmore_Menu] = useState(false)
  const [more_Menu1, setmore_Menu1] = useState(false)


  return (
    <React.Fragment>
      <div className="d-flex p-3">
        <div className="btn-toolbar flex-grow-1" role="toolbar">
          <div className="btn-group me-2 mb-2 mb-sm-0">
            <Button
              type="button"
              color="primary"
              className="waves-light waves-effect"
            >
              <i className="fa fa-inbox" />
            </Button>
            <Button
              type="button"
              color="primary"
              className="waves-light waves-effect"
            >
              <i className="fa fa-exclamation-circle" />
            </Button>
            <Button
              type="button"
              color="primary"
              className="waves-light waves-effect"
            >
              <i className="far fa-trash-alt" />
            </Button>
          </div>
          <Dropdown
            isOpen={folder_Menu}
            toggle={() => {
              setfolder_Menu(!folder_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0">
            <DropdownToggle
              className="btn btn-primary waves-light waves-effect dropdown-toggle"
              tag="i"
            >
              <i className="fa fa-folder" />{" "}
              <i className="mdi mdi-chevron-down ms-1" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to="#">Updates</DropdownItem>
              <DropdownItem to="#">Social</DropdownItem>
              <DropdownItem to="#">Team Manage</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            isOpen={tag_Menu}
            toggle={() => {
              settag_Menu(!tag_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0">
            <DropdownToggle
              className="btn btn-primary waves-light waves-effect dropdown-toggle"
              tag="i"
            >
              <i className="fa fa-tag" />{" "}
              <i className="mdi mdi-chevron-down ms-1" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to="#">Updates</DropdownItem>
              <DropdownItem to="#">Social</DropdownItem>
              <DropdownItem to="#">Team Manage</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            isOpen={more_Menu}
            toggle={() => {
              setmore_Menu(!more_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0"
          >
            <DropdownToggle
              className="btn btn-primary  waves-light waves-effect"
              tag="div"
            >
              More <i className="mdi mdi-dots-vertical ms-2" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to="#">Mark as Unread</DropdownItem>
              <DropdownItem to="#">Mark as Important</DropdownItem>
              <DropdownItem to="#">Add to Tasks</DropdownItem>
              <DropdownItem to="#">Add Star</DropdownItem>
              <DropdownItem to="#">Mute</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="d-flex">
          <Button type="button" color="light" className="me-2 waves-light waves-effect">
          <i className="mdi mdi-refresh"></i>
          </Button>
          <ButtonGroup className="me-auto waves-light waves-effect">
            <Dropdown isOpen={more_Menu1}
            toggle={() => {
              setmore_Menu1(!more_Menu1)
            }}
            className="mb-2 mb-sm-0">
              <DropdownToggle color="light" caret>
              <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="#">Mark as Unread</DropdownItem>
                <DropdownItem href="#">Mark as Important</DropdownItem>
                <DropdownItem href="#">Add to Tasks</DropdownItem>
                <DropdownItem href="#">Add Star</DropdownItem>
                <DropdownItem href="#">Mute</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </ButtonGroup>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmailToolbar
