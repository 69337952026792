import React from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import ReactApexChart from "react-apexcharts";

const series = [70]

const options= {
    chart: {
        
        type: 'radialBar',
    }
    ,
    plotOptions: {
        radialBar: {
           
            hollow: {
                size: '50%',
            }
            ,
            dataLabels: {
                name: {
                    show: false,
                }
                ,
                value: {
                    show: true, fontSize: '14px', fontWeight:'bold', offsetY: 5,
                },
                style: {
                    colors: ['#fff']
                }
            }
        }
        ,
    }
    ,
    colors: ['#2cb57e'],
  }

const PaymentMethod = () => {
  return (
    <React.Fragment>
                <Col xl={4}>
                                <Card>
                                    <CardBody className="pb-0">
                                        <div className="card-info d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-2">Payment Method</h5>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle text-reset" tag="a" to="#">
                                                        <span className="text-muted">Monthly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem className="dropdown-item" to="#">Yearly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Monthly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Weekly</DropdownItem>
                                                        <DropdownItem className="dropdown-item" to="#">Today</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>

                                        <Card className="text-bg-primary visa-card mb-0 mt-3">
                                            <CardBody className="card-body">
                                                <div>
                                                    <i className="bx bxl-visa visa-pattern"></i>
                                                
                                                    <div className="float-end">
                                                        <i className="bx bxl-visa visa-logo display-3"></i>
                                                    </div>
    
                                                    <div>
                                                        <i className="bx bx-chip h1 text-warning"></i>
                                                    </div>
                                                </div>

                                                <Row className="mt-5 mb-2 align-items-center">
                                                    <div className="col-3">
                                                        <p className="mb-0 text-truncate text-center">
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-0 text-truncate text-center">
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-0 text-truncate text-center">
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-0 text-truncate text-center font-size-20">
                                                            4565
                                                        </p>
                                                    </div>
                                                </Row>

                                                <div className="mt-5">
                                                    <h5 className="text-white float-end mb-0">12/22</h5>
                                                    <h5 className="text-white mb-0">Peter</h5>
                                                </div>
                                            </CardBody>
                                        </Card>

                                        <div className="card-info">
                                            <div className="align-items-center d-flex mt-4">
                                                <h4 className="card-title mb-0 flex-grow-1">Card Limits</h4>
                                                <div className="flex-shrink-0">
                                                    <select className="form-select form-select-sm mb-0 my-n1">
                                                        <option defaultValue="ltc">Switch Card</option>
                                                        <option defaultValue="visa">Visa</option>
                                                        <option defaultValue="paypal">Paypal</option>
                                                        <option defaultValue="mastercard">Mastercard</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="monthly-limits">
                                                <ReactApexChart
                                                        options={options}
                                                        series={series}
                                                        type="radialBar"   
                                                        height ={152}                                                     
                                                        className="apex-charts"
                                                    /> 
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="font-size-15">Monthly Limits</h5>
                                                    <p className="text-muted mb-0">$20,000 of $100,000</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <Link to="#" className="btn btn-primary">View Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
    </React.Fragment>
  )
}

export default PaymentMethod;