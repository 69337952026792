import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Container, Col, Input, Label, Card, CardBody } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";

import user1 from "../../assets/images/users/avatar-1.jpg"
import user2 from "../../assets/images/users/avatar-2.jpg"
import user3 from "../../assets/images/users/avatar-3.jpg"
import user4 from "../../assets/images/users/avatar-4.jpg"
import user5 from "../../assets/images/users/avatar-5.jpg"
import user6 from "../../assets/images/users/avatar-6.jpg"
import user7 from "../../assets/images/users/avatar-7.jpg"
import user8 from "../../assets/images/users/avatar-8.jpg"

const members = [
  {
    memberPic : user1,
    memberName :"Peter Johne",
    memberEmail : "peterjohne@gmail.com"

  },
  {
    memberPic : user2,
    memberName :"Allyson Booker",
    memberEmail : "allysonbooker@gmail.com"

  },
  {
    memberPic : user3,
    memberName :"Nikolas Cablela",
    memberEmail : "nikolascablela@gmail.com"

  },
  {
    memberPic : user4,
    memberName :"Kira Collier",
    memberEmail : "kiracollier@gmail.com"

  },
  {
    memberPic : user5,
    memberName :"Jellyn Ferral",
    memberEmail : "jellynferral@gmail.com"

  },
  {
    memberPic : user6,
    memberName :"Damon Boxtar",
    memberEmail : "damonboxtar@gmail.com"

  },
  {
    memberPic : user7,
    memberName :"Ross Jordan",
    memberEmail : "rossjordan@gmail.com"

  },
  {
    memberPic : user8,
    memberName :"Monika Daker",
    memberEmail : "monikadaker@gmail.com"

  },
  
]



const Team = () => {  
  document.title = "Team | Samply - React Admin & Dashboard Template";

  return (
    <React.Fragment>
    <div className="page-content team">
      
            <Container fluid>                
            <Breadcrumbs title="Pages" breadcrumbItem="Team" />
                
                
                <Row className="mb-3">
                    <Col sm={6}>
                        <div className="d-flex align-items-center justify-content-sm-start justify-content-start">
                            <div className="fs-1 text-primary me-2"><i className="mdi mdi-account-supervisor-circle"></i></div>
                            <div>
                                <h4 className="mb-0 fs-6">Team Members</h4>
                            </div>
                        </div>
                    </Col> 
                    <Col sm={6}>
                        <div className="d-flex align-items-center justify-content-sm-end justify-content-start">
                            <div className="me-4">
                                <Link className="fs-2 me-2" to="#"><i className="mdi mdi-dots-grid"></i></Link>
                                <Link className="fs-2 text-muted" to="#"><i className="mdi mdi-menu"></i></Link>
                            </div>
                            <div className="btn-group p-1 rounded-5 bg-white shadow-sm" role="group" aria-label="Basic radio toggle button group">
                                <div>
                                    <Input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                                    <Label className="btn btn-outline-primary rounded-5 mb-0 border-0 px-4" htmlFor="btnradio1">All</Label>
                                </div>
                                <div>
                                    <Input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <Label className="btn btn-outline-primary rounded-5 mb-0 border-0" htmlFor="btnradio3">My Team</Label>
                                </div>
                            </div>
                        </div>
                    </Col> 
                </Row> 
                <Row>
                {members.map((member, key) => (
                    <Col xl={3} key={key}>
                        <Card>
                            <CardBody>
                                <div>
                                    <div>
                                        <div className="float-start fs-3 text-danger">
                                            <i className="mdi mdi-cards-heart"></i>
                                        </div>
                                        <UncontrolledDropdown                                     
                                          className="float-end"
                                        >                                        
                                          <DropdownToggle tag="a" className="fs-3 text-muted">
                                              <i className="mdi mdi-dots-horizontal"></i>
                                          </DropdownToggle>
                                    
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className="dropdown-item" to="#">Edit</DropdownItem>
                                                <DropdownItem className="dropdown-item" to="#">Action</DropdownItem>
                                                <DropdownItem className="dropdown-item" to="#">Remove</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>     

                                    </div>
                                    <div className="clearfix"></div>
                                    
                                    <div className="text-center bg-pattern" >
                                        <img src={member.memberPic} alt="avatar-4" className="avatar-lg rounded-circle mb-3" />
                                        <h5 className="text-primary mb-2">{member.memberName}</h5>
                                        <h6 className="text-muted font-size-13 mb-0">{member.memberEmail}</h6>
                                    </div>
                                    
                                    <hr className="text-muted my-3" />
                                    <div className="text-center team-social">
                                        <Link className="fs-4 text-muted me-3" to="#" role="button">
                                            <i className="mdi mdi-basketball"></i>
                                        </Link>
                                        <Link className="fs-4 text-muted me-3" to="#" role="button">
                                            <i className="mdi mdi-facebook"></i>
                                        </Link>
                                        <Link className="fs-4 text-muted me-3" to="#" role="button">
                                            <i className="mdi mdi-twitter"></i>
                                        </Link>
                                        <Link className="fs-4 text-muted" to="#" role="button">
                                            <i className="mdi mdi-email"></i>
                                        </Link>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        
                    </Col>
                  ))}
                </Row>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="text-center text-sm-start">
                            <p className="mb-sm-0">Showing 1 to 10 of 57 entries</p>
                        </div>
                    </Col> 
                    <Col sm={6}>
                        <div className="page-box">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center justify-content-sm-end">
                                  <li className="page-item">
                                    <Link className="page-link text-primary" to="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                    </Link>
                                  </li>
                                  <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                                  <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                  <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                  <li className="page-item">
                                    <Link className="page-link text-primary" to="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                    </Link>
                                  </li>
                                </ul>
                            </nav>
                        </div>
                    </Col> 
                </Row> 
                
            </Container> 

        </div> 
        </React.Fragment>
  )
}

export default Team