import React from 'react';
import { Container, Row } from 'reactstrap';
import Widget from './Widget';
import MarketOveriview from './MarketOveriview';
import QuickTransfer from './QuickTransfer';
import PaymentMethod from './PaymentMethod';
import RecentFills from './RecentFills';
import CryptocurrencyPrice from './CryptocurrencyPrice';
import SalesByLocation from './SalesByLocation';
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Bitcoin Chart
const series1 = [
  { name: "BTC", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#0576b9"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Etherium Chart
const series2 = [
  { name: "ETH", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1734f"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//LiteCoin Chart
const series3 = [
  { name: "LTC", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#0576b9"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

const Crypto = () => {
  document.title = "Dashboard Crypto | Samply - React Admin & Dashboard Template";

  const reports = [
    {
      title: "Bitcoin BTC",
      icon: "mdi mdi-bitcoin",
      color: "primary",
      value: "$280.526",
      desc: "+1.5%",
      series: series1,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
      badgecolor:'success'
    },
    {
      title: "Ethereum ETH",
      icon: "mdi mdi-ethereum",
      color: "info",
      value: "$280.526",
      desc: "-2.3%",
      series: series2,
      options: options2,
      arrowUpDown: 'mdi mdi-arrow-down ms-1 text-danger',
      badgecolor:'danger'
    },
    {
      title: "Litecoin",
      icon: "mdi mdi-litecoin",
      color: "success",
      value: "$486.257",
      desc: "+3.6%",
      series: series3,
      options: options3,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
      badgecolor:'success'
    },
  ];
  return (

    <div className="page-content">

      <Container fluid>
        <Breadcrumbs title="Samply" breadcrumbItem="Crypto" />
        <Widget reports={reports} />

        <MarketOveriview />


        <Row>

          <QuickTransfer />
          <RecentFills />
          <PaymentMethod />

        </Row>

        <div className="row">
          <CryptocurrencyPrice />
          <SalesByLocation />
        </div>



      </Container>
    </div>


  )
}

export default Crypto