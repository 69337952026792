import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import user1 from "../../assets/images/users/avatar-1.jpg";
import img5 from "../../assets/images/small/img-5.jpg";


const Utilities = () => {
  return (
    <Row>
    <Col lg={12}>
        <Card  id="utilities">
            <CardBody>
                <h4 className="card-title mb-4">Utilities</h4>
                
                <div>
                    <h5 className="font-size-14 mb-3">Borders</h5>
                    <p className="card-title-desc mb-4">Use border utilities to add or remove an element’s borders. Choose from all borders or one at a time.</p>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Border Width</h5>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-1"></div>
                                </div>
                                <code>.border.border-1</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-2"></div>
                                </div>
                                <code>.border.border-2</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-3"></div>
                                </div>
                                <code>.border.border-3</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-4"></div>
                                </div>
                                <code>.border.border-4</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-5"></div>
                                </div>
                                <code>.border.border-5</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Border color</h5>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-primary"></div>
                                </div>
                                <code>.border-primary</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-secondary"></div>
                                </div>
                                <code>.border-secondary</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-success"></div>
                                </div>
                                <code>.border-success</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-info"></div>
                                </div>
                                <code>.border-info</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-warning"></div>
                                </div>
                                <code>.border-warning</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-danger"></div>
                                </div>
                                <code>.border-danger</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-dark"></div>
                                </div>
                                <code>.border-dark</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-transparent border border-light"></div>
                                </div>
                                <code>.border-light</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Additive</h5>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-success border"></div>
                                </div>
                                <code>.border</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-success border-top"></div>
                                </div>
                                <code>.border-top</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-success border-end"></div>
                                </div>
                                <code>.border-end</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-success border-bottom"></div>
                                </div>
                                <code>.border-bottom</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-success border-start"></div>
                                </div>
                                <code>.border-start</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Subtractive</h5>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border-0"></div>
                                </div>
                                <code>.border-0</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-primary border-top-0"></div>
                                </div>
                                <code>.border-top-0</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-primary border-end-0"></div>
                                </div>
                                <code>.border-end-0</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-primary border-bottom-0"></div>
                                </div>
                                <code>.border-bottom-0</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-primary border-start-0"></div>
                                </div>
                                <code>.border-start-0</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Border Opacity</h5>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-info border-opacity-10"></div>
                                </div>
                                <code>.border-opacity-10</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-info border-opacity-25"></div>
                                </div>
                                <code>.border-opacity-25</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-info border-opacity-50"></div>
                                </div>
                                <code>.border-opacity-50</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-info border-opacity-75"></div>
                                </div>
                                <code>.border-opacity-75</code>
                            </div>
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title bg-light border border-info"></div>
                                </div>
                                <code>.border</code>
                            </div>
                        </div>
                    </div>


                    <div className="mb-4">
                        
                        <h5 className="font-size-14 mb-3">Borders Radius</h5>

                        <p className="card-title-desc mb-4">Use below-mentioned class to an element to easily round its corners.</p>
                       <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded d-block mx-auto" />
                                <code>.rounded</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-top d-block mx-auto" />
                                <code>.rounded-top</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-end d-block mx-auto" />
                                <code>.rounded-end</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-bottom d-block mx-auto" />
                                <code>.rounded-bottom</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-start d-block mx-auto" />
                                <code>.rounded-start</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-circle d-block mx-auto" />
                                <code>.rounded-circle</code>
                            </div>
                            <div className="text-center">
                                <img src={img5} alt="" className="avatar-md w-auto rounded-pill d-block mx-auto" />
                                <code>.rounded-pill</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Size</h5>
                    
                        <p className="card-title-desc mb-4">Use below-mentioned class to an element to easily round its corners.</p>
                        <div className="d-flex align-items-start flex-wrap gap-4">
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-0 d-block mx-auto" />
                                <code>.rounded-0</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-1 d-block mx-auto" />
                                <code>.rounded-1</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-2 d-block mx-auto" />
                                <code>.rounded-2</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-3 d-block mx-auto" />
                                <code>.rounded-3</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-4 d-block mx-auto" />
                                <code>.rounded-4</code>
                            </div>
                            <div className="text-center">
                                <img src={user1} alt="" className="avatar-md rounded-5 d-block mx-auto" />
                                <code>.rounded-5</code>
                            </div>
                        </div>
                    </div>

                    <div className="mb-5">
                    
                        <h5 className="font-size-14 mb-3">Box Shadow</h5>
                    
                        <p className="card-title-desc mb-4">While shadows on components are disabled by default in Bootstrap and can be enabled via $enable-shadows, you can also quickly add or remove a shadow with our <code>box-shadow</code> utility classes. Includes support for <code>.shadow-none</code> and three default sizes (which have associated variables to match).</p>
                        
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="shadow-none p-3 mb-4 bg-transparent rounded"><code>.shadow-none</code> No shadow</div>
                            </div>
                            <div className="col-xl-6">
                                <div className="shadow-sm p-3 mb-4 bg-transparent rounded"><code>.shadow-sm</code> Small shadow</div>
                            </div>
                            <div className="col-xl-6">
                                <div className="shadow p-3 mb-4 mb-xl-0 bg-transparent rounded"><code>.shadow</code> Regular shadow</div>
                            </div>
                            <div className="col-xl-6">
                                <div className="shadow-lg p-3 mb-4 mb-xl-0 bg-transparent rounded"><code>.shadow-lg</code> Larger shadow</div>
                            </div>
                        </div>

                    </div>

                    <div className="mb-4">
                    
                        <h5 className="font-size-14 mb-3">Text selection</h5>
                    
                        <p className="card-title-desc mb-4">Change the way in which the content is selected when the user interacts with it.</p>
                    
                        <div>
                            <p className="user-select-all">This paragraph will be entirely selected when clicked by the user.</p>
                            <p className="user-select-auto">This paragraph has default select behavior.</p>
                            <p className="user-select-none mb-0">This paragraph will not be selectable when clicked by the user.</p>
                        </div>
                    
                    </div>


                    <div className="mb-4">
                        <h5 className="font-size-14 mb-3">Width</h5>
                        <p className="card-title-desc mb-4">Width and height utilities are generated from the utility API in <code>_utilities.scss</code>. Includes support for <code>25%</code>, <code>50%</code>, <code>75%</code>, <code>100%</code>, and auto by default. Modify those values as you need to generate different utilities here.</p>
                    
                        <div>
                            <div className="w-25 p-3 bg-light">Width 25%</div>
                            <div className="w-50 p-3 bg-light">Width 50%</div>
                            <div className="w-75 p-3 bg-light">Width 75%</div>
                            <div className="w-100 p-3 bg-light">Width 100%</div>
                            <div className="w-auto p-3 bg-light">Width auto</div>
                        </div>
                    </div>

                    <div>
                        <h5 className="font-size-14 mb-3">Height</h5>
                        <p className="card-title-desc mb-4">Width and height utilities are generated from the utility API in <code>_utilities.scss</code>. Includes support for 25%, 50%, 75%, 100%, and auto by default. Modify those values as you need to generate different utilities here.</p>
                    
                        <div>
                            <div style={{ height : "380px" }}>                            
                                <div className="h-25 d-inline-block bg-light" style={{ width: "120px"}}>Height 25%</div>
                                <div className="h-50 d-inline-block bg-light" style={{ width: "120px"}}>Height 50%</div>
                                <div className="h-75 d-inline-block bg-light" style={{ width: "120px"}}>Height 75%</div>
                                <div className="h-100 d-inline-block bg-light" style={{ width: "120px"}}>Height 100%</div>
                                <div className="h-auto d-inline-block bg-light" style={{ width: "120px"}}>Height auto</div>
                            </div> 
                        </div>
                    </div>

                </div>

            </CardBody>
        </Card>
    </Col>
</Row>
  )
}

export default Utilities;