import React, { useEffect } from 'react';
import Dashboard from '../Dashboard';

const Preloader = () => {
    
    useEffect(() => {
        const preloaderDiv = document.getElementById("preloaderDiv");
        setTimeout(() => {
            if (preloaderDiv) {
                preloaderDiv.style.display = "none";
            }
        }, 2000);
    },[])
    document.title = "Preloader | Samply - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div id="preloaderDiv">
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Dashboard isTitle={true} />
        </React.Fragment>
    )
}

export default Preloader;