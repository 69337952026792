import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import { Link } from 'react-router-dom';

const series = [{
    data: [25, 40, 31, 42, 51, 44, 36, 50, 37, 29, 25, 40, 31, 42, 51,]
}];
var options = {
    fill: {
      colors: ['#0576b9'],
    },
    chart: {
        type: 'bar',
        height: 82,
        width: 310,
        sparkline: {
            enabled: true
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '60%',
            borderRadius: 0,
        }
    },
  
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    xaxis: {
        crosshairs: {
            width: 1
        },
    },
    tooltip: {
        fixed: {
            enabled: false
        },
        x: {
            show: false
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: false
        }
    }
  };


const Widget = props => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={8}>
                    <Row>
                        {props.reports.map((report, key) => (
                            <Col md={6} xl={4} key={key}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="avatar-sm">
                                                <span className={`avatar-title rounded-circle bg-${report.color}-subtle font-size-24`}>
                                                    <i className={`${report.icon} text-${report.color}`}></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="font-size-15 mb-0 text-truncate">{report.title} <span className="text-muted">/ BIDR</span></h5>
                                            </div>
                                            <div className="ms-auto">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="font-size-16 text-muted" to="#">
                                                        <i className="mdi mdi-information font-size-16"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem className="dropdown-item" href="#">Action</DropdownItem>
                                                        <DropdownItem className="dropdown-item" href="#">Another action</DropdownItem>
                                                        <DropdownItem className="dropdown-item" href="#">Something else here</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3 pt-1">
                                            <div>
                                                <h4 className="font-size-20">{report.value}</h4>
                                                <p className={`mb-0 text-${report.badgecolor} fw-semibold font-size-15`}>{report.desc} <i className="bx bx-trending-up align-middle font-size-18"></i></p>
                                            </div>
                                            <div>
                                                <ReactApexChart
                                                    options={report.options}
                                                    series={report.series}
                                                    type="area"
                                                    height={45}
                                                    width={120}
                                                    className="apex-charts"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>


                <Col xl={4}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="align-items-center d-flex mb-3">
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-0 me-2">Available Balance</h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="font-size-16 text-muted" tag="a" to="#">
                                                <i className="mdi mdi-information font-size-16"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="dropdown-item" href="#">Action</DropdownItem>
                                                <DropdownItem className="dropdown-item" href="#">Another action</DropdownItem>
                                                <DropdownItem className="dropdown-item" href="#">Something else here</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h3 className="mb-0">$236,214.00</h3>
                                        <div className="pt-4">
                                            <Link to="#" className="btn btn-success btn-sm me-2 w-md"><i className="bx bx-upload align-middle me-1 font-size-13"></i> Withdraw</Link>
                                        </div>
                                    </div>
                                    <div className="overflow-hidden">
                                        <ReactApexChart
                                            options={options}
                                            series={series}
                                            type="bar"
                                            height={82}
                                            width={310}
                                            className="apex-charts"
                                        />
                                        {/* <div id="mini-1" className="apex-charts" dir="ltr"></div> */}
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Widget;